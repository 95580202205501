import { Frame } from "framer"

export function RoundedBlueSquare() {
    return (
        <Frame
            width={100} // šírka štvorca
            height={100} // výška štvorca
            background={"#0000FF"} // farba pozadia
            radius={20} // zaoblenie rohov
        />
    )
}
